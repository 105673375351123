var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"aside-menu",class:{ toggled: _vm.show }},[_c('div',{staticClass:"aside-menu__fixed"},[_c('burger-button',{staticClass:"menu-toggler",attrs:{"toggled":_vm.show},nativeOn:{"click":function($event){return _vm.handleAsideToggled.apply(null, arguments)}}}),(_vm.userAliases)?_c('div',{staticClass:"icons-bar"},[_vm._l((_vm.userAliases),function(ref,index){
var id = ref.id;
var avatar = ref.avatar;
return _c('div',{key:(id + "-" + index),staticClass:"icons-bar__image",class:{ active: _vm.isActiveAlias(id) },on:{"click":function($event){return _vm.aliasClickHandler(id)}}},[_c('avatar',{attrs:{"size":"md","file":avatar ? avatar.src : ''}})],1)}),(_vm.canCreateNewAlias)?_c('button',{staticClass:"icons-bar__image icons-bar__image--new-account",class:{ disabled: _vm.disabled },attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.showAddAliasPopup.apply(null, arguments)}}},[_c('plus-icon')],1):_vm._e()],2):_vm._e()],1),(_vm.activeAlias)?_c('div',{staticClass:"aside-menu__toggler"},[_c('nav',{staticClass:"aside-menu-navigation"},[(_vm.activeAliasBlog)?_c('h3',[_vm._v(_vm._s(_vm.activeAliasBlog.name))]):_vm._e(),_c('ul',[(_vm.isTablet)?_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{staticClass:"header-navigation__link",attrs:{"to":{ name: 'home', params: { order: _vm.mainFeedOrder } }}},[_vm._v("My Feed")])],1):_vm._e(),(_vm.activeAliasBlogUrl)?_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{staticClass:"header-navigation__link",attrs:{"to":{
              name: 'blog',
              params: {
                name: _vm.activeAliasBlogUrl,
                id: _vm.activeAliasBlog.link ? undefined : 'id'
              }
            }}},[_vm._v("My Blog")])],1):_vm._e(),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{attrs:{"to":{ name: 'my-communities' }}},[_vm._v("My Communities")])],1),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{attrs:{"to":{ name: 'following' }}},[_vm._v("Following"),_c('badge',{attrs:{"info":_vm.activeAlias.followingCount}})],1)],1),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{attrs:{"to":{ name: 'followers' }}},[_vm._v("Followers "),_c('badge',{attrs:{"info":_vm.activeAlias.followedByCount}})],1)],1),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{attrs:{"to":{ name: 'likes', params: { name: _vm.activeAliasBlogUrl } }}},[_vm._v("Likes "),_c('badge',{attrs:{"info":_vm.activeAlias.likesCount}})],1)],1),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{attrs:{"to":{ name: 'activities' }}},[_vm._v(" Activities"),_c('badge',{attrs:{"info":_vm.activeAlias.activitiesCount}})],1)],1),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{attrs:{"to":{ name: 'blocking' }}},[_vm._v("Blocking")])],1),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{attrs:{"to":{ name: 'privacy-groups' }}},[_vm._v("Privacy groups")])],1),_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{class:{
              'router-link-exact-active': _vm.$route.matched.some(
                function (ref) {
                            var settings = ref.meta.settings;

                            return settings;
}
              )
            },attrs:{"to":{ name: 'settings-auth' }}},[_vm._v("Settings")])],1),(_vm.isModerator)?_c('li',{staticClass:"aside-menu-navigation__link"},[_c('router-link',{class:{
              'router-link-exact-active': _vm.$route.matched.some(
                function (ref) {
                            var moderation = ref.meta.moderation;

                            return moderation;
}
              )
            },attrs:{"to":{ name: 'signup-keys' }}},[_vm._v("Moderation")])],1):_vm._e()])]),_c('div',{staticClass:"aside-menu__btns"},[_c('div',{staticClass:"aside-menu__theme-toggler"},[_c('form-toggler',{staticClass:"form-toggler",attrs:{"name":"theme","checked":_vm.themeDark},model:{value:(_vm.themeDarkValue),callback:function ($$v) {_vm.themeDarkValue=$$v},expression:"themeDarkValue"}},[_vm._v(" Dark mode ")])],1),_c('button',{staticClass:"aside-menu__logout-btn",attrs:{"type":"button"},on:{"click":_vm.logoutBtnHandler}},[_vm._v(" Logout "),_c('logout-icon',{staticClass:"logout-icon"})],1),(!_vm.isMobile)?_c('div',{staticClass:"aside-menu__copyrights"},[_c('copyrights')],1):_vm._e()])]):_vm._e(),(_vm.aliasModalSettings.show)?_c('alias-modal',{attrs:{"settings":_vm.aliasModalSettings,"data":_vm.aliasPopupModalData},on:{"close":_vm.handleAliasModalClosed}}):_vm._e(),(_vm.isMobile)?_c('fanexus-footer',{staticClass:"aside-menu__footer"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }