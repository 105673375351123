var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",class:{
    'button--plus': _vm.plus,
    'button--full-width': _vm.fullWidth,
    'button--disabled': _vm.disabled,
    'button--header-button': _vm.headerButton,
    'button--disabled': _vm.disabled,
    'button--small': _vm.small
  },attrs:{"type":_vm.type,"disabled":_vm.disabled}},[_vm._v(" "+_vm._s(_vm.text)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }