var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"tags-list",class:{
    togglable: _vm.showAllBtn,
    'tags-list-input': _vm.isInput
  }},[_vm._l((_vm.tagsToShow),function(ref,index){
  var id = ref.id;
  var name = ref.name;
  var displaySynonym = ref.displaySynonym;
  var tagCount = ref.tagCount;
return _c('li',{key:(name + "-" + index),staticClass:"tags-list__item",class:{
      'tags-list-input__item': _vm.isInput,
      disabled: !_vm.$listeners || !_vm.$listeners.select
    },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select', name)}}},[[_vm._v("#"+_vm._s(displaySynonym || name)),(tagCount)?_c('span',[_vm._v(" ("+_vm._s(tagCount)+")")]):_vm._e(),(_vm.$listeners && _vm.$listeners['remove-tag'])?_c('button',{staticClass:"cross-button",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove-tag', id)}}},[_c('cross-icon',{staticClass:"cross-button__icon"})],1):_vm._e()]],2)}),(!_vm.isInput && _vm.showTagsButton && !_vm.showAllBtn)?_c('button',{staticClass:"tags-list__more",on:{"click":function($event){$event.stopPropagation();return _vm.showMoreTags.apply(null, arguments)}}},[_vm._v(" More ")]):_vm._e(),(_vm.showAllBtn)?_c('button',{staticClass:"tags-list__more--square-btn",class:{ active: _vm.toggled },on:{"click":function($event){$event.stopPropagation();return _vm.toggleVisibility.apply(null, arguments)}}},[_vm._v(" More ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }