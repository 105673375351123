import { render, staticRenderFns } from "./PopularCommunities.vue?vue&type=template&id=02f63529&scoped=true&v-if=data.suggestions&"
import script from "./PopularCommunities.vue?vue&type=script&lang=js&"
export * from "./PopularCommunities.vue?vue&type=script&lang=js&"
import style0 from "./PopularCommunities.vue?vue&type=style&index=0&id=02f63529&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f63529",
  null
  
)

export default component.exports