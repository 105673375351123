var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(" has "),(_vm.data.sourceReblog)?_c('router-link',{attrs:{"to":{
      name: (_vm.reblogIn + "-with-blog-post"),
      params: {
        name: _vm.reblogSource.link || _vm.reblogSource.id,
        postId: _vm.data.sourceReblog.id
      }
    }}},[_vm._v("reblogged")]):_vm._e(),_vm._v(" your "),_c(this.data.targetBlogPost ? 'router-link' : 'span',{tag:"component",attrs:{"to":{
      name: (_vm.blogPostIn + "-with-blog-post"),
      params: {
        name: _vm.blogPostLocation,
        postId: _vm.data.targetBlogPostId
      }
    }}},[_vm._v(_vm._s(_vm.targetType))]),(!_vm.forReblog || _vm.sourceContent.length)?_c('activity-content',{attrs:{"content":_vm.sourceContent,"content-type":"post"}}):_vm._e(),(_vm.data.sourceReblog && _vm.data.sourceReblog.content.trim().length)?[_vm._v(" with comment"),_c('activity-content',{attrs:{"content":_vm.data.sourceReblog.content,"content-type":"post"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }