<template>
  <span class="copyrights"
    >Developed by
    <a target="_blank" rel="nofollow noopener" href="http://vocter.com/"
      >Vocter</a
    >
  </span>
</template>
<script>
export default {
  name: 'copyrights'
};
</script>
