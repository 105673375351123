var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('await-response',{attrs:{"has-response":_vm.loaded}},[_c('section',{staticClass:"likes-comments"},[_c('has-none',{attrs:{"has-content":_vm.data.BlogPosts.length}},_vm._l((_vm.data.BlogPosts.filter(
          function (ref) {
                  var showComment = ref.showComment;

                  return showComment;
}
        )),function(ref,index){
        var id = ref.id;
        var showComment = ref.showComment;
return _c('comment',{key:(id + "-" + index),attrs:{"comment":showComment,"blog-post-id":id,"standalone":true}})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }