var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"options-rows"},_vm._l((_vm.selections),function(ref,index){
var name = ref.name;
var heading = ref.heading;
var values = ref.values;
var selected = ref.selected;
return _c('div',{key:(name + "-" + index),staticClass:"options-rows__row"},[_c('h4',{staticClass:"options-rows__heading",class:{ 'text-overflow': _vm.ellipsesHeading }},[_vm._v(" "+_vm._s(heading)+" ")]),_c('ul',{staticClass:"options-rows__value-list"},_vm._l((values),function(ref,index){
var value = ref.value;
var label = ref.label;
return _c('li',{key:(value + "-" + index),staticClass:"options-rows__value-list-item",class:{ selected: value === selected },on:{"click":function($event){return _vm.$emit('input', { name: name, value: value })}}},[_vm._v(" "+_vm._s(label)+" ")])}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }