var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"members"},[(_vm.success)?_c('flash-message',[_vm._v(_vm._s(_vm.successMessage))]):_vm._e(),_c('tab-content-grid',{attrs:{"showSearch":true,"btnSecondary":"Invite members","btnPrimary":"Delete members","title":"Members","note":_vm.data && _vm.data.userHasEditPermissions
        ? 'As a moderator, you can remove members or make other members moderators from here.'
        : ''},on:{"handleSecondaryBtn":_vm.handleModalOpen,"handle-input":_vm.handleInput}},[(_vm.groups)?[_vm._l((_vm.groups),function(ref,index){
        var aliasId = ref.id;
        var name = ref.name;
        var avatar = ref.avatar;
        var updatedAt = ref.updatedAt;
        var ref_Blog = ref.Blog;
        var id = ref_Blog.id;
        var link = ref_Blog.link;
return _c('group-component',{key:(id + "-" + index),attrs:{"group-component-type":"blog","link":link,"isAddingUser":true,"id":id,"name":name,"image":avatar ? avatar.src : '',"activityName":"Updated at:","date":updatedAt,"userHasEditPermissions":_vm.data && _vm.data.userHasEditPermissions,"options":_vm.dropdownOptionsMembers},on:{"add":function($event){return _vm.addUserAsModerator(aliasId)},"delete":function($event){return _vm.showModal(aliasId)}}})}),(_vm.settings.show)?_c('confirmation-modal',{attrs:{"settings":_vm.settings},on:{"submit":_vm.deleteMember}}):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }