var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invites"},[_c('has-none',{attrs:{"has-content":_vm.invites.length}},[_c('ul',{staticClass:"invites-list"},_vm._l((_vm.invites),function(ref,index){
var id = ref.id;
var inviter = ref.inviter;
var Community = ref.Community;
return _c('li',{key:(id + "-" + index),staticClass:"invites-list__item"},[_c('div',{staticClass:"invites-list__item-message"},[(Community.avatar)?_c('avatar',{staticClass:"invites-list__item-avatar",attrs:{"size":"md","file":Community.avatar.src}}):_vm._e(),_c('router-link',{attrs:{"to":{
              name: 'blog',
              params: { name: inviter.Blog.link || inviter.Blog.id }
            }}},[_vm._v(_vm._s(inviter.name))]),_vm._v(" invited you to "),_c('router-link',{attrs:{"to":{ name: 'community', params: { name: Community.link } }}},[_vm._v(_vm._s(Community.name))]),_vm._v(". ")],1),_c('div',{staticClass:"invites-list__item-actions"},[_c('form-button',{staticClass:"invites-list__item-accept-btn",attrs:{"type":"submit","text":"Accept"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.acceptInvite(Community.id)}}}),_c('form-button',{staticClass:"invites-list__item-reject-btn",attrs:{"type":"submit","text":"Reject"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.rejectInvite(Community.id)}}})],1)])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }