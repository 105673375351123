var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"moderators"},[_c('tab-content-grid',{attrs:{"showSearch":true,"title":"Moderators","note":_vm.data && _vm.data.userHasEditPermissions
        ? 'As a moderator, you can remove other moderators from here.'
        : ''},on:{"handle-input":_vm.handleInput}},[_vm._l((_vm.groups),function(ref,index){
        var aliasId = ref.id;
        var name = ref.name;
        var avatar = ref.avatar;
        var updatedAt = ref.updatedAt;
        var ref_Blog = ref.Blog;
        var id = ref_Blog.id;
        var link = ref_Blog.link;
return _c('group-component',{key:(id + "-" + index),attrs:{"group-component-type":"blog","link":link,"id":id,"activityName":"Updated at","date":updatedAt,"name":name,"options":_vm.dropdownOptionsModerators,"userHasEditPermissions":_vm.data && _vm.data.userHasEditPermissions,"image":avatar ? avatar.src : ''},on:{"delete":function($event){return _vm.showModal(aliasId)}}})}),(_vm.settings.show)?_c('confirmation-modal',{attrs:{"settings":_vm.settings},on:{"submit":_vm.deleteModerator}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }